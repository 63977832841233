import * as React from "react"
import Layout from "components/Layout"
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Navigation } from 'swiper/core';
import "swiper/components/navigation/navigation.min.css"
import 'swiper/swiper.scss';
import '../pages.scss'

export default function Home() {
	SwiperCore.use([Autoplay, Navigation]);

	const principles = [
		{
			img: 'img1',
			text: 'Laço de Amor'
		},
		{
			img: 'img2',
			text: 'Ambiente Preparado'
		},
		{
			img: 'img3',
			text: 'Rotinas e Rituais'
		},
		{
			img: 'img4',
			text: 'Limites e Regras'
		},
		{
			img: 'img5',
			text: 'Observação Ativa'
		}
	]

	const testimonials = [
		{
			name: 'Cliente W',
			img: 'imgw',
			quote: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptatibus quidem cumque dicta reiciendis similique earum dignissimos tenetur, blanditiis fuga mollitia.'
		},
		{
			name: 'Cliente X',
			img: 'imgx',
			quote: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe, unde provident atque voluptatum facilis pariatur blanditiis odit fugiat aliquam nesciunt earum esse vitae rerum in quia voluptas officia, est cupiditate aspernatur ipsam. Ratione atque adipisci sapiente consectetur recusandae sit nesciunt pariatur tempore, accusantium, dolorem magnam? Accusamus architecto sint voluptatibus tempora.'
		},
		{
			name: 'Cliente Y',
			img: 'imgy',
			quote: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sit, ipsam!'
		},
		{
			name: 'Cliente Z',
			img: 'imgz',
			quote: 'Lorem ipsum dolor sit amet.'
		}
	]

	return (
		<Layout>
			<main>
				<section className="section section--fml">
					<div className="hero">
						<Swiper className="hero__slider" autoplay>
							<SwiperSlide><img src="/" alt="img 1" /></SwiperSlide>
							<SwiperSlide><img src="/" alt="img 2" /></SwiperSlide>
							<SwiperSlide><img src="/" alt="img 3" /></SwiperSlide>
						</Swiper>
						<div className="section hero__btn">
							<div className="section__inner">
								<a className="button" href="/">Cadastre-se para receber <br /> os nossos conteúdos</a>
							</div>
						</div>
					</div>
					<div className="section">
						<div className="section__inner">
							<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsam deserunt excepturi esse voluptatem doloremque, voluptatibus rem magni minus! Accusantium ex magni facere ipsa eveniet. Veniam natus, consectetur sunt velit sapiente dignissimos earum esse iure distinctio sint nihil labore impedit qui recusandae assumenda libero mollitia laboriosam quia provident tempora maxime corporis?</p>
						</div>
					</div>
				</section>
				<section className="section">
					<div className="section__inner">
						<h2 className="section__title">Os 5 Princípios da Pedagogia Florença:</h2>
						<ol className="principles">
							{principles.map(el => (
								<li className="principles__item" key={el.img}>
									<img className="principles__img" src={`/${el.img}`} alt={el.img} />
									<h3 className="principles__title">
										<span>{el.text}</span>
									</h3>
								</li>
							))}
						</ol>
					</div>
				</section>
				<section className="section">
					<div className="section__inner">
						<h2 className="section__title">Edição Florença</h2>
						<div className="books">
							<div className="books__item">
								<a href="/" className="books__link">
									<img src="/" alt="img1" />
								</a>
							</div>
							<div className="books__item">
								<a href="/" className="books__link">
									<img src="/" alt="img2" />
								</a>
							</div>
							<div className="books__item">
								<a href="/" className="books__link">
									<img src="/" alt="img3" />
								</a>
							</div>
							<div className="books__item">
								<a href="/" className="books__link">
									<img src="/" alt="img4" />
								</a>
							</div>
							<div className="books__item">
								<a href="/" className="books__link">
									<img src="/" alt="img5" />
								</a>
							</div>
						</div>
						<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt possimus rem maxime fuga a pariatur porro similique voluptatum molestiae fugiat? Quasi consectetur aliquam corporis cum sequi. Non nostrum illo quaerat veritatis nemo doloremque quia id, blanditiis cumque labore! Impedit, nulla? Eius temporibus modi odit repellendus magni veritatis molestiae libero unde.</p>
					</div>
				</section>
				<section className="section section--contact">
					<div className="section__inner">
						<Swiper className="releases" autoplay>
							<SwiperSlide><img className="releases__img" src="/" alt="img 1" /></SwiperSlide>
							<SwiperSlide><img className="releases__img" src="/" alt="img 2" /></SwiperSlide>
							<SwiperSlide><img className="releases__img" src="/" alt="img 3" /></SwiperSlide>
						</Swiper>
						<form className="form" action="">
							<fieldset className="form__fieldset">
								<legend className="form__title">Contato</legend>
								<input className="form__item" type="text" placeholder="nome" />
								<input className="form__item" type="text" placeholder="email" />
								<input className="form__item" type="text" placeholder="telefone" />
								<textarea className="form__item" name="" id=""></textarea>
								<button className="form__item">Enviar</button>
							</fieldset>
						</form>
					</div>
				</section>
				<section className="section">
					<div className="section__inner">
						<h3 className="section__title">Depoimentos</h3>
						<div className="testimonials">
							<div className="testimonials__nav-list">
								<button className="testimonials__nav-item testimonials__nav-item--prev">{`<`}</button>
								<button className="testimonials__nav-item testimonials__nav-item--next">{`>`}</button>
							</div>
							<Swiper
								navigation={{
									nextEl: '.testimonials__nav-item--next',
									prevEl: '.testimonials__nav-item--prev'
								}}
								slidesPerView={1}
								spaceBetween={30}
							>
								{testimonials.map(el => (
									<SwiperSlide key={el.name}>
										<div className="testimonials__card">
											<img className="testimonials__img" src={`/${el.img}`} alt={el.img} />
											<figure className="testimonials__quote">
												<blockquote>
													<p>{el.quote}</p>
												</blockquote>
												<figcaption className="testimonials__author">—{el.name}</figcaption>
											</figure>
										</div>
									</SwiperSlide>
								))}
							</Swiper>
						</div>
					</div>
				</section>
				<section className="section section__infinite">
					<Swiper className="banner" autoplay>
						<SwiperSlide><img className="banner__img" src="/" alt="img 1" /></SwiperSlide>
						<SwiperSlide><img className="banner__img" src="/" alt="img 2" /></SwiperSlide>
						<SwiperSlide><img className="banner__img" src="/" alt="img 3" /></SwiperSlide>
					</Swiper>
				</section>
			</main>
		</Layout>
	)
}
